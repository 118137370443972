import { useDispatch, useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";
import { orderActions } from "@/store/order";

import MealPlanSelectCard from "./MealPlanSelectCard";

const convertToAmPm = (time) => {
  const [hourString, minute] = time.split(":");
  let hour = parseInt(hourString, 10);
  const amPm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;
  return `${hour}:${minute} ${amPm}`;
};

const MealTime = () => {
  const dispatch = useDispatch();
  const { mealTimeLabels, mealTimeDeliveryWindow } = useData();
  const { kitchen, mealTime } = useSelector((state) => state.orderSlice.order);

  const setMealTime = (mealTime) => {
    dispatch(orderActions.updateOrder({ mealTime }));
  };

  const getMealTimeLabel = (mealTime) => {
    return mealTimeLabels[mealTime];
  };

  const mealTimeOptions = kitchen.configuration.mealTimes.map((mealTime) => {
    return {
      value: mealTime,
      label:
        getMealTimeLabel(mealTime) +
        " (" +
        convertToAmPm(mealTimeDeliveryWindow[mealTime].start) +
        " to " +
        convertToAmPm(mealTimeDeliveryWindow[mealTime].end) +
        ")",
    };
  });

  return (
    <MealPlanSelectCard
      label={"Select Meal Time"}
      options={mealTimeOptions}
      selectedOption={mealTime}
      setSelectedOption={setMealTime}
    />
  );
};

export default MealTime;
