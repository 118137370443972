import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";

import generateOrderDates from "../utils/generateOrderDates";

const useOrderDates = () => {
  const { dayToNumberMap, periodToDaysMap } = useData();
  const { kitchen, startDate, period } = useSelector(
    (state) => state.orderSlice.order,
  );
  const [endDate, setEndDate] = useState(null);
  const [deliverableDates, setDeliverableDates] = useState([]);

  useEffect(() => {
    if (!startDate || !period) {
      setEndDate(null);
      setDeliverableDates([]);
      return;
    }

    const { deliverableDates, endDate } = generateOrderDates({
      startDate,
      dayToNumberMap,
      periodToDaysMap,
      period,
      deliverableDays: kitchen.configuration.deliveryDays,
    });

    setEndDate(endDate);
    setDeliverableDates(deliverableDates);
  }, [
    startDate,
    dayToNumberMap,
    periodToDaysMap,
    period,
    kitchen.configuration.deliveryDays,
  ]);

  return { startDate, endDate, deliverableDates };
};

export default useOrderDates;
