import { Alert } from "@mui/material";

import { verticalComponentMargin } from "@/utils/styleConstants";

import Map from "./Map";

const MapWithAlert = ({ latitude, longitude, handleDragEnd }) => {
  if (!latitude || !longitude) return null;
  return (
    <div style={{ margin: verticalComponentMargin }}>
      <Alert severity="info" style={{ marginBottom: "10px" }}>
        Drag the marker below to your exact location
      </Alert>
      <Map
        latitude={latitude}
        longitude={longitude}
        draggable
        handleDragEnd={handleDragEnd}
      />
    </div>
  );
};

export default MapWithAlert;
