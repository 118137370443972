import { Card, CardContent, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import DeliveryDays from "./DeliveryDays";
import Location from "./Location";
import Price from "./Price";
import Rating from "./Rating";
import Tagline from "./Tagline";

const KitchenDetails = () => {
  const { kitchen } = useSelector((state) => state.orderSlice.order);

  const {
    name,
    tagline,
    address: { area },
    configuration: { deliveryDays },
    rating: { average, count },
    priceStartsAt,
  } = kitchen;

  return (
    <Card style={{ margin: "15px 0px" }}>
      <CardContent style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ maxWidth: "65%" }}>
          <Typography variant="h6">{name}</Typography>
          <Tagline tagline={tagline} />
          {area && <Location location={area} />}
          <DeliveryDays deliveryDays={deliveryDays} />
        </div>
        <div>
          <Rating average={average} count={count} />
          <Price price={priceStartsAt} />
        </div>
      </CardContent>
    </Card>
  );
};

export default KitchenDetails;
