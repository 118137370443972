import AddressDetailsDrawer from "@/components/Address/AddressDetailsDrawer";
import CurrentLocation from "@/components/Address/CurrentLocation";
import useAddress from "@/components/Address/hooks/useAddress";
import LocationSearch from "@/components/Address/LocationSearch";
import MapWithAlert from "@/components/Address/MapWithAlert";
import SuggestionList from "@/components/Address/SuggestionList";

import LocationDisplay from "./LocationDisplay";

const AddAddress = ({ address, submitHandler }) => {
  const {
    searchValue,
    setSearchValue,
    suggestions,
    setSuggestions,
    isGeocoding,
    isOpen,
    setIsOpen,
    locationDetails,
    handleSuggestionSelect,
    reverseGeocodeLocation,
    displayAddress,
    addressSubmitHandler,
  } = useAddress({ address, submitHandler });

  return (
    <>
      <LocationSearch
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setSuggestions={setSuggestions}
      />
      <CurrentLocation onLocationCapture={reverseGeocodeLocation} />
      <SuggestionList
        suggestions={suggestions}
        onSuggestionSelect={handleSuggestionSelect}
      />
      {suggestions.length === 0 && (
        <LocationDisplay
          displayAddress={displayAddress}
          latitude={locationDetails.latitude}
          longitude={locationDetails.longitude}
          confirmLocationHandler={() => setIsOpen(true)}
        />
      )}
      {suggestions.length === 0 && !isGeocoding && (
        <MapWithAlert
          latitude={locationDetails.latitude}
          longitude={locationDetails.longitude}
          handleDragEnd={reverseGeocodeLocation}
        />
      )}
      <AddressDetailsDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={"Add Address"}
        displayAddress={displayAddress}
        submitHandler={addressSubmitHandler}
      />
    </>
  );
};

export default AddAddress;
