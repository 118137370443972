import AddAddressPage from "./pages/AddAddressPage";
import AddressPage from "./pages/AddressPage";
import CheckoutPage from "./pages/CheckoutPage";
import KitchenPage from "./pages/KitchenPage";
import KitchensPage from "./pages/KitchensPage";

const createOrderRoutes = [
  {
    path: "kitchens",
    component: <KitchensPage />,
    isProtected: false,
  },
  {
    path: "kitchens/:kitchenId",
    component: <KitchenPage />,
    isProtected: false,
  },
  {
    path: "create-order/address",
    component: <AddressPage />,
    isProtected: true,
  },
  {
    path: "create-order/address/add",
    component: <AddAddressPage />,
    isProtected: true,
  },
  {
    path: "create-order/checkout",
    component: <CheckoutPage />,
    isProtected: true,
  },
];

export default createOrderRoutes;
