import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";

import calculatePrice from "../../utils/calculatePrice";

const convertRateToPercentage = (rate) => `${rate * 100}%`;

const PriceBreakdown = ({ menuScheduleDays, deliverableDates }) => {
  const data = useData();

  const order = useSelector((state) => state.orderSlice.order);
  const { cuisine, mealType, mealTime, period, quantity } = order;

  const currencySymbol = "₹";

  const { basePrice, gstPrice, totalPrice } = calculatePrice({
    menuScheduleDays,
    deliverableDates,
    quantity,
  });

  const formatCurrency = (value) => {
    const absoluteValue = Math.abs(value);
    if (isNaN(absoluteValue)) return "-";
    return value < 0
      ? `-${currencySymbol}${absoluteValue}`
      : `${currencySymbol}${absoluteValue}`;
  };

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Categories</TableCell>
            <TableCell>Details</TableCell>
            <TableCell>Price</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Meal plan</TableCell>
            <TableCell>{`${quantity} x ${data.cuisineLabels[cuisine]} ${data.mealTypeLabels[mealType]} ${data.mealTimeLabels[mealTime]} - ${data.periodLabels[period]}`}</TableCell>
            <TableCell>{formatCurrency(basePrice)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>GST</TableCell>
            <TableCell>{convertRateToPercentage(data.gstRate)}</TableCell>
            <TableCell>{formatCurrency(gstPrice)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell style={{ fontWeight: 600 }}>Total Price</TableCell>
            <TableCell style={{ fontWeight: 600 }}>
              {formatCurrency(totalPrice)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PriceBreakdown;
