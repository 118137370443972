const constructdisplayAddress = (locationDetails) => {
  const { area, locality, state, pincode, country } = locationDetails;

  let displayAddress = "";

  if (area) displayAddress += area + ", ";
  if (locality) displayAddress += locality + ", ";
  if (state) displayAddress += state + " ";
  if (pincode) displayAddress += pincode + ", ";
  if (country) displayAddress += country;

  displayAddress = displayAddress.trim();
  if (displayAddress.endsWith(","))
    displayAddress = displayAddress.slice(0, -1);

  return displayAddress;
};

export default constructdisplayAddress;
