import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import Title from "@/components/Title";
import api from "@/api";
import { orderActions } from "@/store/order";
import handleError from "@/utils/handleError";

import AddAddress from "./components/AddAddress";

const AddAddressPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { location: gsLocation } = useSelector((state) => state.kitchensSlice);

  let address = {};

  if (gsLocation.type === "googlePlace" || gsLocation.type === "gps") {
    address = {
      ...gsLocation.locationDetails,
      location: {
        coordinates: [
          gsLocation.locationDetails.longitude,
          gsLocation.locationDetails.latitude,
        ],
      },
    };
  }

  const submitHandler = async (addressData) => {
    try {
      const {
        data: { address },
      } = await api.customerAddresses.create(addressData);
      dispatch(
        orderActions.updateOrder({
          address,
        }),
      );
      navigate("/create-order/checkout", {
        replace: true,
      });
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <Title>Address</Title>
        <AddAddress address={address} submitHandler={submitHandler} />
      </Container>
    </>
  );
};

export default AddAddressPage;
