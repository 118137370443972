import { Card, CardContent, Radio, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const SelectableAddressCard = ({ address, selected, onSelect }) => {
  const theme = useTheme();
  const { contactName, contactNumber, displayAddress } = address;

  return (
    <Card
      style={{
        minWidth: 275,
        marginBottom: "15px",
        border: selected
          ? `2px solid ${theme.palette.primary.main}`
          : "2px solid transparent",
        cursor: "pointer",
      }}
      onClick={() => onSelect(address)}
    >
      <CardContent style={{ padding: "10px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Radio checked={selected} style={{ marginRight: "10px" }} />
          <div>
            <Typography variant="subtitle1">{contactName}</Typography>
            <Typography variant="subtitle2">{contactNumber}</Typography>
            <Typography variant="body2">{displayAddress}</Typography>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default SelectableAddressCard;
