import { Container } from "@mui/material";
import { useSelector } from "react-redux";

import AddressSummary from "@/components/AddressSummary";
import Header from "@/components/Header";
import Title from "@/components/Title";

import { OrderSummary, PaymentButton } from "./components";
import PageGuard from "./hoc/PageGuard";

const CheckoutPage = () => {
  const { address } = useSelector((state) => state.orderSlice.order);

  return (
    <PageGuard>
      <Header />
      <Container>
        <Title>Checkout</Title>
        <OrderSummary />
        <AddressSummary address={address} allowChange />
        <PaymentButton />
      </Container>
    </PageGuard>
  );
};

export default CheckoutPage;
