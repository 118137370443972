import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "@/components/Header";
import OrDivider from "@/components/OrDivider";
import Title from "@/components/Title";
import api from "@/api";
import handleError from "@/utils/handleError";

import AddNewAddressButton from "./components/AddNewAddressButton";
import ExistingAddresses from "./components/ExistingAddresses";

const AddressPage = () => {
  const navigate = useNavigate();
  const { kitchen } = useSelector((state) => state.orderSlice.order);
  const [deliverableAddresses, setDeliverableAddresses] = useState([]);

  useEffect(() => {
    const fetchDeliverableAddresses = async () => {
      try {
        const {
          data: { addresses },
        } = await api.customerAddresses.getDeliverableAddressesForKitchen({
          kitchenId: kitchen._id,
        });
        setDeliverableAddresses(addresses);

        if (addresses.length === 0) {
          navigate("/create-order/address/add", {
            replace: true,
          });
        }
      } catch (error) {
        handleError(error);
      }
    };

    fetchDeliverableAddresses();
  }, [navigate, kitchen._id]);

  return (
    <>
      <Header />
      <Container>
        <Title>Address</Title>
        <AddNewAddressButton />
        <OrDivider />
        <ExistingAddresses addresses={deliverableAddresses} />
      </Container>
    </>
  );
};

export default AddressPage;
