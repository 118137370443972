import { useState } from "react";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

import CurrentLocation from "@/components/Address/CurrentLocation";
import LocationSearch from "@/components/Address/LocationSearch";
import SuggestionList from "@/components/Address/SuggestionList";
import Header from "@/components/Header";

import SavedAddresses from "../../../welcome/pages/WelcomePage/components/SavedAddresses";

const LocationSearchPage = () => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleCoordinates = ({ latitude, longitude }) => {
    const location = {
      type: "gps",
      latitude,
      longitude,
    };
    localStorage.setItem("location", JSON.stringify(location));
    navigate("/");
  };

  const handleSuggestionSelect = (suggestion) => {
    const location = {
      type: "googlePlace",
      googlePlaceId: suggestion.placePrediction.placeId,
    };
    localStorage.setItem("location", JSON.stringify(location));
    navigate("/");
  };

  return (
    <>
      <Header />
      <Container style={{ paddingTop: "20px" }}>
        <LocationSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setSuggestions={setSuggestions}
        />
        <CurrentLocation onLocationCapture={handleCoordinates} />
        <SuggestionList
          suggestions={suggestions}
          onSuggestionSelect={handleSuggestionSelect}
        />
        <SavedAddresses hideList={suggestions.length > 0} />
      </Container>
    </>
  );
};

export default LocationSearchPage;
