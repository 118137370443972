import { useEffect } from "react";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";

import { orderActions } from "@/store/order";
import { verticalComponentMargin } from "@/utils/styleConstants";

const EndDate = ({ endDate }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const setEndDate = (date) => {
      dispatch(orderActions.updateOrder({ endDate: date }));
    };

    if (endDate) {
      setEndDate(new Date(endDate).toISOString());
    } else {
      setEndDate("");
    }
  }, [dispatch, endDate]);

  if (!endDate) {
    return null;
  }

  return (
    <TextField
      size="small"
      fullWidth
      label="End Date"
      value={new Date(endDate).toDateString()}
      InputProps={{
        readOnly: true,
      }}
      style={{ display: "block", margin: verticalComponentMargin }}
      disabled
    />
  );
};

export default EndDate;
