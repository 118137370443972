const calculatePrice = ({ menuScheduleDays, deliverableDates, quantity }) => {
  const dayToPriceMapper = {};

  menuScheduleDays.forEach((menuScheduleDay) => {
    dayToPriceMapper[menuScheduleDay.day] =
      menuScheduleDay.menuSchedule.menu.price;
  });

  let basePrice = 0;

  const numberToDayMapper = {
    0: "sun",
    1: "mon",
    2: "tue",
    3: "wed",
    4: "thu",
    5: "fri",
    6: "sat",
  };

  deliverableDates.forEach((date) => {
    basePrice += dayToPriceMapper[numberToDayMapper[new Date(date).getDay()]];
  });

  basePrice *= quantity;

  const gstPrice = Math.round(basePrice * 0.05);

  const totalPrice = basePrice + gstPrice;

  return {
    basePrice,
    gstPrice,
    totalPrice,
  };
};

export default calculatePrice;
