import { useState } from "react";
import { ArrowUpward } from "@mui/icons-material";
import { Button, Container } from "@mui/material";

import BottomDrawer from "@/components/BottomDrawer";

import DeliveryCalendar from "./DeliveryCalendar/DeliveryCalendar";

const ViewCalendarButton = ({ startDate, endDate, deliverableDates }) => {
  const [open, setOpen] = useState(false);

  if (!startDate || !endDate || !deliverableDates) {
    return null;
  }

  return (
    <>
      <Button
        fullWidth
        onClick={() => setOpen(true)}
        variant="outlined"
        size="small"
        color="success"
        endIcon={<ArrowUpward />}
      >
        View Calendar
      </Button>
      <BottomDrawer
        isOpen={open}
        setIsOpen={setOpen}
        title={"Delivery Calendar"}
      >
        <Container>
          <DeliveryCalendar
            startDate={startDate}
            endDate={endDate}
            deliverableDates={deliverableDates}
          />
        </Container>
      </BottomDrawer>
    </>
  );
};

export default ViewCalendarButton;
