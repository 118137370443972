import { useDispatch, useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";
import { orderActions } from "@/store/order";

import MealPlanSelectCard from "./MealPlanSelectCard";

const Cuisine = () => {
  const dispatch = useDispatch();
  const { cuisineLabels } = useData();
  const { kitchen, cuisine } = useSelector((state) => state.orderSlice.order);

  const setCuisine = (cuisine) => {
    dispatch(orderActions.updateOrder({ cuisine }));
  };

  const cuisineOptions = kitchen.configuration.cuisines.map((cuisine) => {
    return {
      value: cuisine,
      label: cuisineLabels[cuisine],
    };
  });

  return (
    <MealPlanSelectCard
      label="Select Cuisine"
      options={cuisineOptions}
      selectedOption={cuisine}
      setSelectedOption={setCuisine}
    />
  );
};

export default Cuisine;
