import { ArrowForward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const AddNewAddressButton = () => {
  const navigate = useNavigate();

  const addNewAddressHandler = () => {
    navigate(`/create-order/address/add`);
  };

  return (
    <Button
      variant="contained"
      fullWidth
      onClick={addNewAddressHandler}
      endIcon={<ArrowForward />}
    >
      Add new address
    </Button>
  );
};

export default AddNewAddressButton;
