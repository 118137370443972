import { useEffect } from "react";
import { Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import getLocationDetailsByGooglePlace from "@/components/Address/utils/getLocationDetailsByGooglePlace";
import getLocationDetailsByReverseGeocode from "@/components/Address/utils/getLocationDetailsByReverseGeocode.js";
import Header from "@/components/Header";
import NoListFoundText from "@/components/NoListFoundText";
import api from "@/api";
import { useAuth } from "@/contexts/AuthContext";
import { kitchensActions } from "@/store/kitchens";
import handleError from "@/utils/handleError";

import { KitchenCard, KitchenSearchAndFilters } from "./components";

const KitchensPage = () => {
  const { isLoggedIn } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { location: gsLocation, filteredKitchens } = useSelector(
    (state) => state.kitchensSlice,
  );

  const getAddressById = async (addressId) => {
    try {
      const {
        data: { address },
      } = await api.customerAddresses.get({ addressId });
      if (address) {
        return address;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const getKitchens = async ({ latitude, longitude }) => {
    const {
      data: { kitchens },
    } = await api.kitchens.getAllKitchens({
      latitude,
      longitude,
    });
    return kitchens;
  };

  useEffect(() => {
    const processLocationAndFetchKitchens = async () => {
      try {
        const lsLocation = JSON.parse(localStorage.getItem("location"));

        switch (lsLocation?.type) {
          case "googlePlace":
            const lsGooglePlaceId = lsLocation.googlePlaceId;
            const gsGooglePlaceId = gsLocation.googlePlaceId;
            if (lsGooglePlaceId && lsGooglePlaceId !== gsGooglePlaceId) {
              const locationDetails =
                await getLocationDetailsByGooglePlace(lsGooglePlaceId);
              if (locationDetails) {
                const { latitude, longitude } = locationDetails;
                const kitchens = await getKitchens({ latitude, longitude });
                dispatch(kitchensActions.setKitchens(kitchens));
                dispatch(
                  kitchensActions.setLocation({
                    type: "googlePlace",
                    googlePlaceId: lsGooglePlaceId,
                    locationDetails,
                  }),
                );
                return;
              } else {
                localStorage.removeItem("location");
              }
            }
            break;

          case "gps":
            const lsLatitude = lsLocation?.latitude;
            const lsLongitude = lsLocation?.longitude;
            const gsLatitude = gsLocation?.latitude;
            const gsLongitude = gsLocation?.longitude;
            if (
              lsLatitude &&
              lsLongitude &&
              lsLatitude !== gsLatitude &&
              lsLongitude !== gsLongitude
            ) {
              const locationDetails = await getLocationDetailsByReverseGeocode({
                latitude: lsLatitude,
                longitude: lsLongitude,
              });

              if (locationDetails) {
                const { latitude, longitude } = locationDetails;
                const kitchens = await getKitchens({ latitude, longitude });
                dispatch(kitchensActions.setKitchens(kitchens));
                dispatch(
                  kitchensActions.setLocation({
                    type: "gps",
                    latitude,
                    longitude,
                    locationDetails,
                  }),
                );
                localStorage.setItem(
                  "location",
                  JSON.stringify({ type: "gps", latitude, longitude }),
                );
                return;
              } else {
                localStorage.removeItem("location");
              }
            }
            break;

          case "address":
            const lsAddressId = lsLocation.addressId;
            const gsAddressId = gsLocation.addressId;
            if (isLoggedIn && lsAddressId && lsAddressId !== gsAddressId) {
              const address = await getAddressById(lsAddressId);
              if (address) {
                const {
                  location: {
                    coordinates: [longitude, latitude],
                  },
                } = address;
                const kitchens = await getKitchens({ latitude, longitude });

                dispatch(kitchensActions.setKitchens(kitchens));
                dispatch(
                  kitchensActions.setLocation({
                    type: "address",
                    addressId: lsAddressId,
                    address,
                  }),
                );
                return;
              } else {
                localStorage.removeItem("location");
              }
            }
            break;
          default:
            localStorage.removeItem("location");
            dispatch(kitchensActions.resetLocation());
            dispatch(kitchensActions.resetKitchens());
            navigate("/welcome", { replace: true });
            break;
        }
      } catch (error) {
        handleError(error);
        localStorage.removeItem("location");
        dispatch(kitchensActions.resetLocation());
        dispatch(kitchensActions.resetKitchens());
        navigate("/welcome", { replace: true });
      }
    };

    processLocationAndFetchKitchens();
  }, [
    dispatch,
    navigate,
    isLoggedIn,
    gsLocation.googlePlaceId,
    gsLocation.latitude,
    gsLocation.longitude,
    gsLocation.addressId,
  ]);

  return (
    <>
      <Header hideBackButton showLocation hideLogo />
      <Container>
        <KitchenSearchAndFilters />
        {filteredKitchens.map((kitchen) => (
          <KitchenCard key={kitchen._id} kitchen={kitchen} />
        ))}
        {filteredKitchens.length === 0 && (
          <NoListFoundText>No kitchens found</NoListFoundText>
        )}
      </Container>
    </>
  );
};

export default KitchensPage;
