import CallIcon from "@mui/icons-material/Call";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import { Button, Card, CardContent } from "@mui/material";
import { useNavigate } from "react-router-dom";

import IconText from "./IconText";

const AddressSummary = ({ address, allowChange }) => {
  const navigate = useNavigate();
  const { label, contactName, contactNumber, displayAddress } = address;
  return (
    <Card style={{ margin: "20px 0" }}>
      <CardContent>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <div>
            <IconText icon={<PersonIcon />} text={contactName} />
            <IconText icon={<CallIcon />} text={contactNumber} />
          </div>
          {allowChange && (
            <Button
              size="small"
              variant="outlined"
              color="warning"
              onClick={() => navigate("/create-order/address")}
            >
              Change
            </Button>
          )}
        </div>
        <IconText icon={<HomeIcon />} text={`${label} - ${displayAddress}`} />
      </CardContent>
    </Card>
  );
};

export default AddressSummary;
