import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import api from "@/api";
import handleError from "@/utils/handleError";

const useMenuSchedulesDays = () => {
  const {
    kitchen: { _id: kitchenId },
    cuisine,
    mealType,
    mealTime,
  } = useSelector((state) => state.orderSlice.order);
  const [menuScheduleDays, setMenuScheduleDays] = useState([]);

  useEffect(() => {
    const fetchMenuSchedules = async () => {
      try {
        const {
          data: { menuScheduleDays },
        } = await api.menuSchedules.getMenuScheduleDays({
          kitchenId,
          cuisine,
          mealType,
          mealTime,
        });

        setMenuScheduleDays(menuScheduleDays);
      } catch (error) {
        handleError(error);
      }
    };

    if (cuisine && mealType && mealTime) {
      fetchMenuSchedules();
    }
  }, [kitchenId, cuisine, mealType, mealTime]);

  return { menuScheduleDays };
};

export default useMenuSchedulesDays;
