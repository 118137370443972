function extractAddressComponents(
  addressComponents,
  extractText = "long_name",
) {
  const sublocality = [];
  let locality = "";
  let state = "";
  let pincode = "";
  let country = "";

  addressComponents.forEach(({ types, [extractText]: long_name }) => {
    if (types.includes("sublocality")) {
      sublocality.push(long_name);
    } else if (types.includes("locality")) {
      locality = long_name;
    } else if (types.includes("administrative_area_level_1")) {
      state = long_name;
    } else if (types.includes("postal_code")) {
      pincode = long_name;
    } else if (types.includes("country")) {
      country = long_name;
    }
  });

  const area = sublocality.join(", ");

  const result = {
    area,
    locality,
    state,
    pincode,
    country,
  };

  return result;
}

export default extractAddressComponents;
