import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from "@/components/Select";
import { useData } from "@/contexts/DataContext";
import { orderActions } from "@/store/order";
import { verticalComponentMargin } from "@/utils/styleConstants";

import generateStartDates from "../utils/generateStartDates";

const StartDate = () => {
  const { dayToNumberMap } = useData();
  const dispatch = useDispatch();
  const { kitchen, startDate } = useSelector((state) => state.orderSlice.order);
  const deliverableDays = kitchen.configuration.deliveryDays;

  const setStartDate = useCallback(
    (date) => {
      dispatch(orderActions.updateOrder({ startDate: date }));
    },
    [dispatch],
  );

  const options = useMemo(() => {
    const startDates = generateStartDates({
      deliverableDays,
      dayToNumberMap,
    });
    return startDates.map((date) => ({
      value: date,
      label: new Date(date).toDateString(),
    }));
  }, [deliverableDays, dayToNumberMap]);

  useEffect(() => {
    if (!startDate && options.length > 0) {
      setStartDate(options[0].value);
    }
  }, [startDate, options, setStartDate]);

  return (
    <Select
      style={{ margin: verticalComponentMargin }}
      label={"Start Date"}
      options={options}
      value={startDate}
      onChange={setStartDate}
    />
  );
};

export default StartDate;
