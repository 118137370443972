import { useDispatch, useSelector } from "react-redux";

import { useData } from "@/contexts/DataContext";
import { orderActions } from "@/store/order";

import MealPlanSelectCard from "./MealPlanSelectCard";

const Period = () => {
  const dispatch = useDispatch();
  const { periodLabels } = useData();
  const { kitchen, period } = useSelector((state) => state.orderSlice.order);

  const setPeriod = (period) => {
    dispatch(orderActions.updateOrder({ period }));
  };

  const periodOptions = kitchen.configuration.periods.map((period) => {
    return {
      value: period,
      label: periodLabels[period],
    };
  });
  return (
    <MealPlanSelectCard
      label={"Select Period"}
      options={periodOptions}
      selectedOption={period}
      setSelectedOption={setPeriod}
    />
  );
};

export default Period;
