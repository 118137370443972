import { useState } from "react";
import { ArrowUpward } from "@mui/icons-material";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";

import MenuDrawer from "./MenuDrawer";

const ViewMenusButton = ({ menuScheduleDays }) => {
  const { cuisine, mealTime, mealType } = useSelector(
    (state) => state.orderSlice.order,
  );
  const [isOpen, setIsOpen] = useState(false);

  if (!cuisine || !mealTime || !mealType || !menuScheduleDays.length) {
    return null;
  }

  return (
    <>
      <Button
        size="small"
        fullWidth
        onClick={() => setIsOpen(true)}
        variant="outlined"
        color="success"
        endIcon={<ArrowUpward />}
      >
        View menus
      </Button>
      <MenuDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        menuScheduleDays={menuScheduleDays}
      />
    </>
  );
};

export default ViewMenusButton;
