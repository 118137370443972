import axios from "axios";

import getAll from "@/utils/getAll";

let base = "/customerAddresses/";

const create = async ({
  label,
  contactName,
  contactNumber,
  building,
  area,
  landmark,
  locality,
  state,
  pincode,
  country,
  latitude,
  longitude,
  googleMapsLocationData,
}) =>
  (
    await axios.post(base, {
      label,
      contactName,
      contactNumber,
      building,
      area,
      landmark,
      locality,
      state,
      pincode,
      country,
      latitude,
      longitude,
      googleMapsLocationData,
    })
  ).data;

const getAllAddress = getAll(base);

const get = async ({ addressId }) => (await axios.get(base + addressId)).data;

const update = async ({ addressId, address }) =>
  (await axios.patch(base + addressId, address)).data;

const remove = async ({ addressId }) =>
  (await axios.delete(base + addressId)).data;

const getDeliverableAddressesForKitchen = async ({ kitchenId }) =>
  (await axios.get(base + "kitchens/" + kitchenId + "/deliverable-addresses"))
    .data;

const addresses = {
  create,
  getAll: getAllAddress,
  get,
  update,
  delete: remove,
  getDeliverableAddressesForKitchen,
};

export default addresses;
