const formatOrderPayload = (order) => {
  const address = order.address;
  return {
    kitchenId: order.kitchen._id,
    cuisine: order.cuisine,
    mealType: order.mealType,
    mealTime: order.mealTime,
    period: order.period,
    priceDetails: order.priceDetails,
    startDate: order.startDate,
    quantity: order.quantity,
    address: {
      label: address.label,
      contactName: address.contactName,
      contactNumber: address.contactNumber,
      building: address.building,
      area: address.area,
      landmark: address.landmark,
      locality: address.locality,
      state: address.state,
      pincode: address.pincode,
      country: address.country,
      latitude: address.location.coordinates[1],
      longitude: address.location.coordinates[0],
      googleMapsLocationData: address.googleMapsLocationData,
    },
  };
};

export default formatOrderPayload;
