import { Container } from "@mui/material";

import Header from "@/components/Header";

import BottomSection from "./components/BottomSection";
import Cuisine from "./components/Cuisine";
import EndDate from "./components/EndDate";
import KitchenDetails from "./components/KitchenDetails";
import MealTime from "./components/MealTime";
import MealType from "./components/MealType";
import Period from "./components/Period";
import Quantity from "./components/Quantity";
import StartDate from "./components/StartDate";
import ViewCalendarButton from "./components/ViewCalendarButton";
import ViewMenusButton from "./components/ViewMenusButton";
import KitchenGuard from "./hoc/KitchenGuard";
import useMenuSchedulesDays from "./hooks/useMenuScheduleDays";
import useOrderDates from "./hooks/useOrderDates";

const KitchenPageContent = () => {
  const { menuScheduleDays } = useMenuSchedulesDays();
  const { startDate, endDate, deliverableDates } = useOrderDates();

  return (
    <>
      <Header />
      <Container style={{ paddingBottom: "100px" }}>
        <KitchenDetails />
        <Cuisine />
        <MealType />
        <MealTime />
        <ViewMenusButton menuScheduleDays={menuScheduleDays} />
        <StartDate />
        <Period />
        <EndDate endDate={endDate} />
        <ViewCalendarButton
          startDate={startDate}
          endDate={endDate}
          deliverableDates={deliverableDates}
        />
        <Quantity />
        <BottomSection
          menuScheduleDays={menuScheduleDays}
          deliverableDates={deliverableDates}
        />
      </Container>
    </>
  );
};

const KitchenPage = () => {
  return (
    <KitchenGuard>
      <KitchenPageContent />
    </KitchenGuard>
  );
};

export default KitchenPage;
