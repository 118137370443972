import { Card, CardContent } from "@mui/material";

import { verticalComponentMargin } from "@/utils/styleConstants";

import Label from "./Label";
import RadioButtons from "./RadioButtons";

const MealPlanSelectCard = ({
  label,
  options,
  selectedOption,
  setSelectedOption,
}) => {
  return (
    <Card variant="outlined" style={{ margin: verticalComponentMargin }}>
      <CardContent>
        <Label label={label} />
        <RadioButtons
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </CardContent>
    </Card>
  );
};

export default MealPlanSelectCard;
