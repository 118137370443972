import { Card, CardContent, Container, Typography } from "@mui/material";
import { useSelector } from "react-redux";

import BottomDrawer from "@/components/BottomDrawer";
import { useData } from "@/contexts/DataContext";

const MenuDrawer = ({ isOpen, setIsOpen, menuScheduleDays }) => {
  const { cuisineLabels, mealTypeLabels, mealTimeLabels, dayLabels } =
    useData();
  const { cuisine, mealType, mealTime } = useSelector(
    (state) => state.orderSlice.order,
  );

  return (
    <BottomDrawer isOpen={isOpen} setIsOpen={setIsOpen} title="Menus">
      <Container style={{ paddingBottom: "30px" }}>
        <Typography
          variant="h6"
          textAlign={"center"}
          style={{ margin: "15px 0px" }}
        >
          {cuisineLabels[cuisine]} {mealTypeLabels[mealType]}{" "}
          {mealTimeLabels[mealTime]}
        </Typography>
        {menuScheduleDays.map(({ day, menuSchedule }) => {
          return (
            <Card key={day} style={{ marginBottom: "10px" }}>
              <CardContent>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  fontWeight={"bold"}
                >
                  {dayLabels[day]}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body2">
                    {menuSchedule.menu.title}
                  </Typography>
                  <Typography variant="body2">
                    ₹{menuSchedule.menu.price}
                  </Typography>
                </div>
                <Typography variant="caption" color={"text.secondary"}>
                  {menuSchedule.menu.description}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Container>
    </BottomDrawer>
  );
};

export default MenuDrawer;
