import { Container } from "@mui/material";

import BottomButton from "../BottomButton";
import Price from "../Price";

const BottomSection = ({ menuScheduleDays, deliverableDates }) => {
  if (!menuScheduleDays.length || !deliverableDates.length) {
    return null;
  }

  return (
    <Container
      style={{
        zIndex: 1,
        position: "fixed",
        left: 0,
        bottom: "20px",
      }}
    >
      <div
        style={{
          background: "#fff",
          border: "1px solid #ddd",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          padding: "10px 15px",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Price
          menuScheduleDays={menuScheduleDays}
          deliverableDates={deliverableDates}
        />
        <BottomButton />
      </div>
    </Container>
  );
};

export default BottomSection;
