import { useCallback, useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { orderActions } from "@/store/order";

import PriceBreakdownDrawer from "./PriceBreakdownDrawer";
import calculatePrice from "../../utils/calculatePrice";

const Price = ({ menuScheduleDays, deliverableDates }) => {
  const dispatch = useDispatch();
  const { priceDetails, quantity } = useSelector(
    (state) => state.orderSlice.order,
  );
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  const setPriceDetails = useCallback(
    (priceDetails) => {
      dispatch(orderActions.updateOrder({ priceDetails }));
    },
    [dispatch],
  );

  useEffect(() => {
    const { basePrice, gstPrice, totalPrice } = calculatePrice({
      menuScheduleDays,
      deliverableDates,
      quantity,
    });

    setPriceDetails({
      basePrice,
      gstPrice,
      totalPrice,
    });
  }, [deliverableDates, menuScheduleDays, setPriceDetails, quantity]);

  if (!priceDetails.totalPrice) {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        color="success"
        onClick={openDrawer}
        endIcon={<KeyboardArrowUpIcon />}
      >
        Price ₹{priceDetails.totalPrice}
      </Button>
      <PriceBreakdownDrawer
        isOpen={open}
        setIsOpen={setOpen}
        menuScheduleDays={menuScheduleDays}
        deliverableDates={deliverableDates}
      />
    </>
  );
};

export default Price;
