import { Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { orderActions } from "@/store/order";

import SelectableAddressCard from "./SelectableAddressCard";

const ExistingAddresses = ({ addresses }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const order = useSelector((state) => state.orderSlice.order);
  const selectedAddress = order.address;

  let sortedAddresses = addresses;

  if (selectedAddress._id) {
    sortedAddresses.sort((a) => {
      return a._id === selectedAddress._id ? -1 : 1;
    });
  }

  const handleSelect = (address) => {
    dispatch(orderActions.updateOrder({ address }));
    navigate("/create-order/checkout");
  };

  return (
    <>
      <Typography
        variant="body1"
        style={{ textAlign: "center", marginBottom: "20px" }}
      >
        Choose from saved addresses
      </Typography>
      {sortedAddresses.map((address) => (
        <SelectableAddressCard
          key={address._id}
          address={address}
          selected={selectedAddress._id === address._id}
          onSelect={handleSelect}
        />
      ))}
    </>
  );
};

export default ExistingAddresses;
