import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import SearchInput from "@/components/SearchInput";
import Select from "@/components/Select";
import { useData } from "@/contexts/DataContext";
import { kitchensActions } from "@/store/kitchens";

const sortByOptions = [
  { value: "numRatings", label: "Popularity" },
  { value: "rating", label: "Rating" },
  { value: "costLTH", label: "Cost: Low to High" },
  { value: "costHTL", label: "Cost: High to Low" },
];

const KitchenSearchAndFilters = () => {
  const dispatch = useDispatch();
  const data = useData();

  const {
    kitchens,
    kitchenSearchTerm,
    sortBy,
    cuisine,
    mealType,
    mealTime,
    period,
  } = useSelector((state) => state.kitchensSlice);

  const setKitchenSearchTerm = (searchTerm) =>
    dispatch(kitchensActions.setKitchenSearchTerm(searchTerm));

  const setSortBy = (sortBy) => dispatch(kitchensActions.setSortBy(sortBy));

  const setCuisine = (cuisine) => dispatch(kitchensActions.setCuisine(cuisine));

  const setMealType = (mealType) =>
    dispatch(kitchensActions.setMealType(mealType));

  const setMealTime = (mealTime) =>
    dispatch(kitchensActions.setMealTime(mealTime));

  const setPeriod = (period) => dispatch(kitchensActions.setPeriod(period));

  const clearFilters = () => {
    dispatch(kitchensActions.setKitchenSearchTerm(""));
    dispatch(kitchensActions.setSortBy(""));
    dispatch(kitchensActions.setCuisine(""));
    dispatch(kitchensActions.setMealType(""));
    dispatch(kitchensActions.setMealTime(""));
    dispatch(kitchensActions.setPeriod(""));
  };

  const isAnyFilterSelected =
    kitchenSearchTerm || sortBy || cuisine || mealType || mealTime || period;

  useEffect(() => {
    let filteredKitchensList = kitchens.filter((kitchen) => {
      return kitchen.name
        .toLowerCase()
        .includes(kitchenSearchTerm.toLowerCase());
    });

    if (cuisine) {
      filteredKitchensList = filteredKitchensList.filter((kitchen) => {
        return kitchen.configuration.cuisines.includes(cuisine);
      });
    }

    if (mealType) {
      filteredKitchensList = filteredKitchensList.filter((kitchen) => {
        return kitchen.configuration.mealTypes.includes(mealType);
      });
    }

    if (mealTime) {
      filteredKitchensList = filteredKitchensList.filter((kitchen) => {
        return kitchen.configuration.mealTimes.includes(mealTime);
      });
    }

    if (period) {
      filteredKitchensList = filteredKitchensList.filter((kitchen) => {
        return kitchen.configuration.periods.includes(period);
      });
    }

    if (sortBy) {
      if (sortBy === "rating") {
        filteredKitchensList.sort((a, b) => {
          return b.rating.average - a.rating.average;
        });
      } else if (sortBy === "numRatings") {
        filteredKitchensList.sort((a, b) => {
          return b.rating.count - a.rating.count;
        });
      } else if (sortBy === "costLTH") {
        filteredKitchensList.sort((a, b) => {
          return a.priceStartsAt - b.priceStartsAt;
        });
      } else if (sortBy === "costHTL") {
        filteredKitchensList.sort((a, b) => {
          return b.priceStartsAt - a.priceStartsAt;
        });
      }
    }

    dispatch(kitchensActions.setFilteredKitchens(filteredKitchensList));
  }, [
    kitchens,
    kitchenSearchTerm,
    sortBy,
    cuisine,
    mealType,
    mealTime,
    period,
    dispatch,
  ]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflowX: "scroll",
          scrollbarWidth: "none",
          padding: "15px 0px 10px",
        }}
      >
        <SearchInput
          label={"Search Kitchens"}
          searchQuery={kitchenSearchTerm}
          setSearchQuery={setKitchenSearchTerm}
          margin={"0px 10px 0px 0px"}
        />
        <Select
          label="Sort by"
          options={sortByOptions}
          value={sortBy}
          onChange={setSortBy}
          clearable={true}
        />
      </div>
      <div
        style={{
          display: "flex",
          overflowX: "scroll",
          scrollbarWidth: "none",
          padding: "10px 0px 15px",
        }}
      >
        <Select
          label="Cuisine"
          options={data.cuisinesData}
          value={cuisine}
          onChange={setCuisine}
          clearable={true}
          style={{ marginRight: "10px" }}
        />
        <Select
          label="Meal Type"
          options={data.mealTypesData}
          value={mealType}
          onChange={setMealType}
          clearable={true}
          style={{ marginRight: "10px" }}
        />
        <Select
          label="Meal Time"
          options={data.mealTimesData}
          value={mealTime}
          onChange={setMealTime}
          clearable={true}
          style={{ marginRight: "10px" }}
        />
        <Select
          label="Period"
          options={data.periodsData}
          value={period}
          onChange={setPeriod}
          clearable={true}
        />
      </div>
      {isAnyFilterSelected && (
        <Button size="small" onClick={clearFilters}>
          Clear Filters
        </Button>
      )}
    </div>
  );
};

export default KitchenSearchAndFilters;
